






















import Vue from "vue";
import _ from "lodash";
import store from "../../store/shop";
import ShopAPI from "../../common/api/api_shop";
import { NotificationTimeLimit } from "@/model/entity/notificationTimeLimit";
import moment from "moment";

const api = new ShopAPI();

export default Vue.extend({
  mixins: [],
  props: {},
  data() {
    return {
      titleList: {
        "mb__add-color-purple": "を開始してください。",
        "mb__add-color-orange": "がまもなくラストオーダーです。",
        "mb__add-color-gray": "の注文受付を終了しました。",
      },
    };
  },
  mounted() {
    api.set_token(store.state.token);
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    notifications(): NotificationTimeLimit[] {
      return _.chain(store.state.notificationTimeLimit)
        .groupBy('order_id')
        .map((data) => {
            return data[0];
        })
        .map((item) => {
          item.style = this.styleNotfication(item);
          return item;
        })
        .filter((item: NotificationTimeLimit) => {
          return !this.isClose(item) && item.style !== "";
        })
        .value();
    },
    styleNotfication: () => (notification: NotificationTimeLimit) => {
      if (!notification.start_time) {
        return "mb__add-color-purple";
      }
      const currentTime = moment();
      const lastTime = moment(notification.last_order_time);
      const remainTime = lastTime.diff(currentTime, "minutes");
      if (currentTime.isAfter(lastTime)) {
        return "mb__add-color-gray";
      } else if (remainTime < 10) {
        // before 10 minutes
        return "mb__add-color-orange";
      }
      return "";
    },
    isClose: () => (notification: NotificationTimeLimit) => {
      return store.state.notificationLimitClose.includes(
        `${notification.id}:${notification.style}`
      );
    },
  },
  methods: {
    notificationTitle(notification: NotificationTimeLimit) {
      const tableSession = _.find(
        store.state.tableSessions,
        (table) => table.id === notification.table_session_id
      );
      return `${tableSession.table_no} 「${
        notification.category_option.title
      }」${this.titleList[notification.style]}`;
    },
    onClose(notification: NotificationTimeLimit) {
      store.commit(
        "setNotificationDisappears",
        `${notification.id}:${notification.style}`
      );
    },
    showNotification(notification) {
      // Display text status
      let statusText = '';

      if (!notification.start_time) {
        statusText = 'を開始してください。';
      }
      const currentTime = moment();
      const lastTime = moment(notification.last_order_time);
      const remainTime = lastTime.diff(currentTime, "minutes");
      if (currentTime.isAfter(lastTime)) {
        statusText = 'の注文受付を終了しました。';
      } else if (remainTime < 10) {
        // before 10 minutes
        statusText = 'がまもなくラストオーダーです。';
      }

      // Display Seat name or Receipt
      let seatName = '';
      let receiptNo = '';

      if (store.state.shopSetting.has_guide) {
        _.forEach(store.state.tableGroups, function (tableGroup) {
          _.forEach(tableGroup.table_seats, function (table_seat) {
            if (table_seat.table_sessions.length && table_seat.table_sessions[0].id == notification.table_session_id) {
              seatName = table_seat.name;
            }
          });
        });

        return seatName + '「' + notification.order.menu_name + '」' + statusText;
      } else {
        let tableSession = _.find(store.state.tableSessions, (tableSession) => tableSession.id == notification.table_session_id);

        if (tableSession) {
          receiptNo = tableSession.no.toString();
        }

        return 'No.' + receiptNo + '「' + notification.order.menu_name + '」' + statusText;
      }
    },
  },
  filters: {},
});
